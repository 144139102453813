import { ChevronRightIcon, SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useState } from 'react'

export default function Example({
  mobileTitle = `Did you know Rune offers Managed IT?`,
  title = `Welcome to Rune 🎉! Did you know we can help with Managed IT? `,
  href = `/business-it/managed-it`,
  hrefText = `Learn more`,
}) {
  const [showBanner, setShowBanner] = useState(true)
  return showBanner ? (
    <div className="bg-sky-600">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg bg-sky-800 p-2">
              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 flex w-full justify-center truncate font-medium text-white">
              <span className="md:hidden">{mobileTitle}</span>
              <span className="hidden md:inline">{title}</span>
            </p>
          </div>
          <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <Link href={href} passHref>
              <span className="flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-sky-600 shadow-sm hover:bg-sky-50">
                {hrefText}
              </span>
            </Link>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" onClick={() => setShowBanner(false)} />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
