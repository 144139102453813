import React from 'react'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'

type Props = {
  title: string
  description?: string
  extraClasses?: string
  show: boolean
}
export default function Tooltip({ title = 'title', description, extraClasses = '', show = false }: Props) {
  return (
    <Transition.Root
      show={show}
      as={Fragment}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div>
        <div className={classNames('absolute bottom-9 left-2 -translate-x-1/2', extraClasses)}>
          <div className="max-w-12 container relative mx-auto rounded bg-gray-100 p-2">
            <p className="!mb-0 text-sm font-semibold leading-none text-gray-800">{title}</p>
            {description ? <p className=" pt-2 pb-2 text-xs leading-none text-gray-600">{description}</p> : null}
            <svg
              className="absolute left-1/2 bottom-[-10px] z-10 -translate-x-1/2 "
              width={16}
              height={10}
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 10L0 0L16 1.41326e-06L8 10Z" fill="#f3f4f6" />
            </svg>
          </div>
        </div>
      </div>
    </Transition.Root>
  )
}
