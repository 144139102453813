import { useEffect, useRef, useState } from 'react'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import ContactDetails from './atoms/ContactDetails'
import React from 'react'
import { noop } from '../lib/helpers'
import axios from 'axios'
import CenteredModalWithButton from './CenteredModalWithButton'
import Button from './atoms/Button'
import { useForm } from 'react-hook-form'
import { EMAIL_REGEX, PHONE_REGEX } from '../lib/constants'
import dynamic from 'next/dynamic'
const ReCAPTCHA = dynamic(() => import('react-google-recaptcha')) as any

declare global {
  interface Window {
    socketConnected: boolean
  }
}

export default function Example({ onOpenCalendar = noop, onSubmitSuccessAck = noop }) {
  const recaptchaRef = useRef(null as any)
  const [state, setState] = useState('idle')
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()

  const successOptions = {
    title: 'Successfully sent message!',
    description: 'You will hear from us soon!',
    action: 'Okay',
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
    ),
    setOpen: setShowModal,
  }

  const [modalOptions, setModalOptions] = useState(successOptions)

  const submit = async () => {
    if (!email.length && !phone.length) {
      setError('neitherItemlist', {
        type: 'manual',
        message: 'You must fill out either email or phone',
      })
      return
    }
    setState('Loading')
    try {
      const captcha = await recaptchaRef.current.execute()
      await axios.post('/api/contact', { email, phone, message, captcha })
      setState('Success')
      setEmail('')
      setModalOptions(successOptions)
      // window.grecaptcha.reset();
    } catch (e: any) {
      setState('Error')
      setModalOptions({
        title: 'Unable to send message.',
        description: e?.response?.data?.error || 'Please try again later.',
        action: 'Okay',
        icon: (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        ),
        setOpen: setShowModal,
      })
    } finally {
      setShowModal(true)
    }
  }

  return (
    <div className="overflow-hidden bg-white px-4 py-8 sm:px-6 lg:px-8">
      <div className="relative mx-auto max-w-xl">
        <svg
          className="absolute left-full translate-x-1/2 transform"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg
          className="absolute right-full bottom-0 -translate-x-1/2 transform"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Contact Rune</h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">How may we assist you today?</p>
          <ContactDetails onOpenCalendar={onOpenCalendar} hideChat={!window.socketConnected} />
        </div>
        <div className="mt-8">
          <form
            action="#"
            method="POST"
            className="grid grid-cols-1 gap-y-6"
            id="simple-form"
            onSubmit={handleSubmit(submit)}
          >
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  {...register('email', { pattern: EMAIL_REGEX })}
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-sky-500 focus:ring-sky-500"
                  onChange={(e) => {
                    setEmail(e.target.value)
                    clearErrors('neitherItemlist')
                    clearErrors('email')
                  }}
                  value={email}
                />
                {errors.email && <p className="mt-4 text-red-600">Please provide a valid email address</p>}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                Phone
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  {...register('phone', { pattern: PHONE_REGEX })}
                  type="text"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 focus:border-sky-500 focus:ring-sky-500"
                  placeholder="+1 (555) 987-6543"
                  onChange={(e) => {
                    setPhone(e.target.value)
                    clearErrors('neitherItemlist')
                    clearErrors('phone')
                  }}
                  value={phone}
                />
                {errors.phone && <p className="mt-4 text-red-600">Please provide a valid 10 digit phone number</p>}
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                  Message
                </label>
                <span id="message-optional" className="text-sm text-gray-500">
                  Optional
                </span>
              </div>
              <div className="mt-1">
                <textarea
                  {...register('message')}
                  id="message"
                  name="message"
                  placeholder="We quickly respond to all messages."
                  rows={3}
                  className="block w-full resize-y rounded-md border border-gray-300 py-3 px-4 shadow-sm focus:border-sky-500 focus:ring-sky-500"
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                  value={message}
                />
              </div>
            </div>
            {errors.neitherItemlist && <p className="text-red-600">{errors.neitherItemlist.message}</p>}
            <div className="sm:col-span-2">
              <Button
                type="submit"
                className="mt-2 inline-flex h-12 w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                text="Send Message"
                state={state as any}
              />
            </div>
            {ReCAPTCHA && <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.RECAPTCHA_KEY!} />}
          </form>
          <CenteredModalWithButton
            {...modalOptions}
            open={showModal}
            onClose={() => setShowModal(false)}
            onClick={() => {
              if (state === 'Success') {
                onSubmitSuccessAck()
              }
              setShowModal(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}
