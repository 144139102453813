import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { PHONE_SUPPORT_LINK, PHONE_SUPPORT, BUSINESS_IT_EMAIL, EMAIL_SUPPORT } from '../lib/constants'
import CopyToClipboard from './atoms/CopyToClipboard'

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8">
          <div>
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Business IT</h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                Would you like to hear more about our services or have a question? We&apos;d love to hear from you.
                Please contact us at:
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <Link href={`tel:${PHONE_SUPPORT_LINK}`} passHref>
                    <PhoneIcon
                      className="h-6 w-6 cursor-pointer text-gray-400 transition-colors hover:text-sky-600"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <div className="flex">
                    <Link href={`tel:${PHONE_SUPPORT_LINK}`} passHref>
                      <p className="cursor-pointer text-gray-500 transition-colors hover:text-sky-600">
                        {PHONE_SUPPORT}
                      </p>
                    </Link>
                    <CopyToClipboard
                      textToCopy={PHONE_SUPPORT_LINK!}
                      className="ml-2 text-gray-500 transition-colors hover:text-sky-600"
                    />
                  </div>
                  <p className="mt-1">Mon-Fri 8am to 5pm PST</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <Link href={`mailto:${BUSINESS_IT_EMAIL}`} passHref>
                    <MailIcon
                      className="h-6 w-6 cursor-pointer  text-gray-400 transition-colors hover:text-sky-600"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
                <div className="ml-3 flex text-base text-gray-500">
                  <Link href={`mailto:${BUSINESS_IT_EMAIL}`} passHref>
                    <p className="cursor-pointer text-gray-500 transition-colors hover:text-sky-600">
                      {BUSINESS_IT_EMAIL}
                    </p>
                  </Link>
                  <CopyToClipboard
                    textToCopy={BUSINESS_IT_EMAIL!}
                    className="ml-2 text-gray-500 transition-colors hover:text-sky-600"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Technical Support</h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                Are you having problems with your technology? Give us a call or send us an email and we’ll get you back
                up and running.
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <Link href={`tel:${PHONE_SUPPORT_LINK}`} passHref>
                    <PhoneIcon
                      className="h-6 w-6 cursor-pointer text-gray-400 transition-colors hover:text-sky-600"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <div className="flex">
                    <Link href={`tel:${PHONE_SUPPORT_LINK}`} passHref>
                      <p className="text-gray-00 cursor-pointer transition-colors hover:text-sky-600">
                        {PHONE_SUPPORT}
                      </p>
                    </Link>
                    <CopyToClipboard
                      textToCopy={PHONE_SUPPORT_LINK!}
                      className="ml-2 text-gray-500 transition-colors hover:text-sky-600"
                    />
                  </div>
                  <p className="mt-1">Mon-Sun 8am to 5pm PST</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <Link href={`mailto:${EMAIL_SUPPORT}`} passHref>
                    <MailIcon
                      className="h-6 w-6 cursor-pointer text-gray-400 transition-colors hover:text-sky-600"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
                <div className="ml-3 flex text-base text-gray-500">
                  <Link href={`mailto:${EMAIL_SUPPORT}`} passHref>
                    <p className="cursor-pointer text-gray-500 transition-colors hover:text-sky-600">{EMAIL_SUPPORT}</p>
                  </Link>
                  <CopyToClipboard
                    textToCopy={EMAIL_SUPPORT!}
                    className="ml-2 text-gray-500 transition-colors hover:text-sky-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
