import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import Image from 'next/image'
import Footer from './Footer'
import Banner from './Banner'
import TwoColumnSupportContact from './TwoColumnSupportContact'
import CenteredModal from './CenteredModal'
import SimpleContactForm from './SimpleContactForm'
import { PopupModal } from 'react-calendly'
import { MARKETING_SITE_URL, BLOG_SITE_URL, BANNER_TITLES } from '../lib/constants'
import {} from '../lib/constants'

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Home', href: `${MARKETING_SITE_URL}/`, current: false },
  { name: 'Services', href: `${MARKETING_SITE_URL}/services`, current: false },
  { name: 'About', href: `${MARKETING_SITE_URL}/about`, current: false },
  { name: 'Blog', href: `${BLOG_SITE_URL}/`, current: false },
]

const BANNER_PROPS = BANNER_TITLES[Math.floor(Math.random() * BANNER_TITLES.length)]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ children, title, current }: any) {
  const [showContactForm, setShowContactForm] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  navigation.forEach((i) => {
    if (i.name === current) {
      i.current = true
    } else {
      i.current = false
    }
  })
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Popover as="header" className="relative z-10">
          <div className="border-b border-gray-200 bg-white">
            <nav
              className="relative mx-auto flex h-16 max-w-7xl items-center justify-between px-6 xl:px-8"
              aria-label="Global"
            >
              <div className="flex w-full items-center justify-between md:w-10 lg:w-auto">
                <Link href="/" passHref>
                  <div>
                    <span className="sr-only">Rune</span>
                    <Image
                      className="h-8 w-auto sm:h-10"
                      width={44}
                      height={40}
                      src="/assets/images/workflow-mark.svg"
                      alt=""
                    />
                  </div>
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="focus-ring-inset focus:ring-grape-500 inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden h-full items-center justify-center space-x-10 md:flex">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'border-b-2 border-sky-500'
                        : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                      'block flex h-full items-center py-2 pl-3 pr-4 text-base font-medium'
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden md:flex md:items-center md:space-x-6">
                <button onClick={() => setShowContactForm(true)}>
                  <span className="text-grape-600 z-50 cursor-pointer rounded-md border border-transparent bg-white py-2 px-6 text-base font-medium shadow-md hover:bg-gray-50">
                    Contact
                  </span>
                </button>
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top transform p-2 transition md:hidden">
              <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <Image
                      className="h-8 w-auto"
                      width={44}
                      height={40}
                      src="/assets/images/workflow-mark.svg"
                      alt="Rune"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="focus:ring-grape-500 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="xs:text-base flex justify-between pt-5 pb-6 text-sm">
                  <div className="flex items-center px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 font-medium text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="xxs:pr-7 xs:px-5 flex items-center">
                    <button onClick={() => setShowContactForm(true)}>
                      <span className="text-grape-600 xxs:px-2 xs:px-4 z-50 cursor-pointer rounded-md border border-transparent bg-white py-2 font-medium shadow-md hover:bg-gray-50 sm:px-6">
                        Contact
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <Banner {...BANNER_PROPS} />

        <div className={title ? 'py-10' : ''}>
          {title ? (
            <header>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">{title}</h1>
              </div>
            </header>
          ) : (
            ''
          )}

          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <div className="px-4 py-8 sm:px-0">{children}</div>
              {/* /End replace */}
            </div>
          </main>
        </div>
        <div id="root"></div>
        <TwoColumnSupportContact />
        <Footer />
        <CenteredModal
          title="Contact"
          open={showContactForm}
          onClose={() => {
            setShowContactForm(false)
            setTimeout(() => setShowCalendar(false), 300)
          }}
        >
          <>
            {showCalendar ? (
              <PopupModal
                url="https://calendly.com/rune-repair/experiment1et"
                // pageSettings={this.props.pageSettings}
                // utm={this.props.utm}
                // prefill={this.props.prefill}
                onModalClose={() => {
                  setShowContactForm(false)
                  setShowCalendar(false)
                }}
                open={showCalendar && showContactForm}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById('root')!}
              />
            ) : (
              <SimpleContactForm
                onOpenCalendar={() => {
                  setShowCalendar(true)
                }}
                onSubmitSuccessAck={() => setShowContactForm(false)}
              />
            )}
          </>
        </CenteredModal>
      </div>
    </>
  )
}
