import { MouseEvent, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import CenteredModalWithButton from './CenteredModalWithButton'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import Button from './atoms/Button'
import React from 'react'
import { EMAIL_REGEX } from '../lib/constants'
import { useForm } from 'react-hook-form'
import dynamic from 'next/dynamic'
const ReCAPTCHA = dynamic(() => import('react-google-recaptcha')) as any

export default function NewsletterSubscribe() {
  const recaptchaRef = useRef(null as any)
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm()
  const [email, setEmail] = useState('')
  const [state, setState] = useState('idle')
  const [showModal, setShowModal] = useState(false)
  const successOptions = {
    title: 'Successfully subscribed!',
    description: 'Thanks for joining the newsletter!',
    action: 'Okay',
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
    ),
    setOpen: setShowModal,
  }
  const [modalOptions, setModalOptions] = useState(successOptions)

  const subscribe = async () => {
    setState('Loading')
    try {
      const captcha = await recaptchaRef.current.execute()
      await axios.post('/api/subscribe', { email, captcha })
      setState('Success')
      setEmail('')
      setModalOptions(successOptions)
    } catch (e: any) {
      setState('Error')
      setModalOptions({
        title: 'Unable to subscribe',
        description: e.response.data.error,
        action: 'Okay',
        icon: (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        ),
        setOpen: setShowModal,
      })
    } finally {
      setShowModal(true)
    }
  }

  return (
    <div className="mt-8 xl:mt-0">
      <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">Subscribe to our newsletter</h3>
      <p className="mt-4 text-base text-gray-500">
        The latest news, articles, and resources, sent to your inbox weekly.
      </p>
      <form className="mt-4 sm:flex sm:max-w-md" onSubmit={handleSubmit(subscribe)}>
        <label htmlFor="email" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          {...register('email', { pattern: EMAIL_REGEX })}
          id="email"
          autoComplete="email"
          required
          className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:placeholder-gray-400 focus:outline-none focus:ring-sky-500"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
            clearErrors('email')
          }}
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <Button
            type="submit"
            className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-base font-medium text-white transition-colors hover:bg-sky-700 focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            text="Subscribe"
            state={state as any}
          />
          {ReCAPTCHA && <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.RECAPTCHA_KEY!} />}
        </div>
      </form>
      {errors.email && <p className="mt-4 text-red-600">Please provide a valid email address</p>}
      <CenteredModalWithButton
        {...modalOptions}
        open={showModal}
        onClose={() => setShowModal(false)}
        onClick={() => setShowModal(false)}
      />
    </div>
  )
}
