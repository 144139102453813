import { noop } from '../../lib/helpers'
import Spinner from './Spinner'

type Props = {
  type: 'button' | 'submit' | 'reset' | undefined
  text: string
  className: string
  loadingText?: string
  state: 'Loading' | 'Success' | 'Error' | 'idle'
  onClick?: any
}

export default function Button({
  type = 'submit',
  text = 'Subscribe',
  loadingText = 'Processing...',
  className = 'w-full bg-sky-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white transition-colors hover:bg-sky-700 focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:cursor-not-allowed',
  state = 'idle',
  onClick = noop,
}: Props) {
  return (
    <button type={type} disabled={state === 'Loading'} className={className} onClick={onClick}>
      {state === 'Loading' ? <Spinner loadingText={loadingText} /> : text}
    </button>
  )
}
