import { CheckCircleIcon, ClipboardCopyIcon, ExclamationIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import React, { useState } from 'react'
import Notification from './Notification'
import Tooltip from './Tooltip'

type Props = {
  textToCopy: string
  className?: string
}

export default function CopyButton({ textToCopy = '', className = '' }: Props) {
  const [copied, setCopied] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [error, setError] = useState(false)

  const copyToClipboard = async () => {
    await navigator.clipboard
      .writeText(textToCopy)
      .catch((err) => setError(true))
      .finally(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
          setError(false)
        }, 3000)
      })
  }

  return (
    <div className="relative">
      <ClipboardCopyIcon
        className={classNames('h-6 w-6 cursor-pointer transition-colors', className)}
        onMouseEnter={(e) => setShowTooltip(true)}
        onMouseLeave={(e) => setShowTooltip(false)}
        onClick={copyToClipboard}
      />
      <Tooltip extraClasses="!left-4" title={copied ? 'Copied!' : 'Copy'} show={showTooltip} />
      <Notification
        title={error ? 'Failed to copy to clipboard.' : 'Copied to clipboard.'}
        show={copied}
        setShow={setCopied}
        icon={
          error ? (
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          ) : (
            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
          )
        }
      />
    </div>
  )
}
