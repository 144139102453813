import { PhoneIcon, MailIcon, CalendarIcon, ChatIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useState } from 'react'
import { PopupModal } from 'react-calendly'
import { PHONE_SUPPORT_LINK, PHONE_SUPPORT, EMAIL_SUPPORT } from '../../lib/constants'
import { noop } from '../../lib/helpers'

declare global {
  interface Window {
    socketConnected: boolean
  }
}

async function openChat() {
  if (window.socketConnected) {
    ;(await import('react-chat-widget')).toggleWidget()
  }
}

export default function ContactDetails({ onOpenCalendar = noop, hideChat = false, hideAppointment = false }) {
  return (
    <dl className="xs:grid-cols-2 mt-8 grid grid-cols-1 gap-y-6 gap-x-4 text-base">
      <dd className="flex text-sky-600">
        <Link href={`tel:${PHONE_SUPPORT_LINK}`} passHref>
          <span className="flex cursor-pointer transition-colors hover:text-sky-700 hover:underline">
            <PhoneIcon className="h-6 w-6 flex-shrink-0 text-sky-600" aria-hidden="true" />
            <span className="ml-3">{PHONE_SUPPORT}</span>
          </span>
        </Link>
      </dd>
      <dd className="flex text-sky-600">
        <Link href={`mailto:${EMAIL_SUPPORT}`} passHref>
          <span className="flex cursor-pointer text-sky-600 transition-colors hover:text-sky-700 hover:underline">
            <MailIcon className="h-6 w-6 flex-shrink-0 text-sky-600" aria-hidden="true" />
            <span className="ml-3">{EMAIL_SUPPORT}</span>
          </span>
        </Link>
      </dd>
      {hideAppointment ? (
        ''
      ) : (
        <dd className="flex text-sky-600">
          <button onClick={onOpenCalendar}>
            <span className="flex cursor-pointer text-sky-600 transition-colors hover:text-sky-700 hover:underline">
              <CalendarIcon className="h-6 w-6 flex-shrink-0 text-sky-600" aria-hidden="true" />
              <span className="ml-3">Book Appointment</span>
            </span>
          </button>
        </dd>
      )}
      {hideChat ? (
        ''
      ) : (
        <dd className="flex text-sky-600">
          <button onClick={openChat}>
            <span className="flex cursor-pointer text-sky-600 transition-colors hover:text-sky-700 hover:underline">
              <ChatIcon className="h-6 w-6 flex-shrink-0 text-sky-600" aria-hidden="true" />
              <span className="ml-3">Chat with Rune</span>
            </span>
          </button>
        </dd>
      )}
    </dl>
  )
}
